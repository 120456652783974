import getConfig from 'next/config'
import SvgAccount from '@ntuctech/devex-tangram/Icon/SvgAccount'

import OrdersIcon from '../components/icons/OrdersNew'
import HelpIcon from '../components/icons/HelpNew'
import PreferencesIcon from '../components/icons/Preferences'
import DigitalClubIcon from '../components/icons/DigitalClub'

const {
  publicRuntimeConfig: { GIFTING_DOMAIN, FPG_SINGLE_ACCOUNT_PAGE_DOMAIN_NEW },
} = getConfig()

export const ACCOUNTS_MENU = {
  payment: 'Payment methods',
  profile: 'Profile',
  businessProfile: 'Business Profile',
  address: 'Address',
  preferences: 'Preferences',
  vouchers: 'Vouchers',
  luckyDraw: 'Lucky draw',
  plus: 'Link',
  digitalClub: 'Digital Club',
}

const aboutUsUrl = 'https://www.fairpricegroup.com.sg/'

export const LINKS = {
  recipes: {
    label: 'Recipes',
    url: 'https://www.fairprice.com.sg/recipes/',
    isAbsoluteUrl: true,
  },
  tipsForYou: {
    label: 'Tips for You',
    url: 'https://www.fairprice.com.sg/tips/',
    isAbsoluteUrl: true,
  },
  storeLocator: {
    label: 'Store Locator',
    url: '/store-locator',
  },
  events: {
    label: 'Events',
    url: 'https://www.fairprice.com.sg/events/',
    isAbsoluteUrl: true,
  },
  justWineClub: {
    label: 'Just Wine Club',
    url: '/loyalty/programmes/jwc',
  },
  digitalClub: {
    label: 'Digital Club',
    url: '/membership/registration',
  },
  shopAndDonate: {
    label: 'Shop & Donate',
    url: 'https://donate.fairprice.com.sg/donate',
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  shopForBusiness: {
    label: 'Shop for Business',
    url: '/',
    isNewTab: false,
    isAbsoluteUrl: false,
  },
  sendEGiftVoucher: {
    label: 'Send E-Gift Vouchers',
    url: GIFTING_DOMAIN,
    isNewTab: false,
    isAbsoluteUrl: true,
  },
  shopForPersonal: {
    label: 'Shop for Personal',
    url: '/',
    isNewTab: false,
    isAbsoluteUrl: false,
  },
  help: {
    label: 'Help',
    url: 'https://help.fairprice.com.sg/',
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  aboutUs: {
    label: 'About FairPrice Group',
    url: aboutUsUrl,
    isAbsoluteUrl: true,
  },
  aboutUsB2B: {
    label: 'About FairPrice Group',
    url: aboutUsUrl,
    isAbsoluteUrl: true,
  },
  plus: {
    label: 'Link Rewards',
    url: 'https://link.sg/',
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  storeWeeklyAds: {
    label: 'Weekly promotions',
    url: '/weekly-promotions',
    isAbsoluteUrl: false,
  },
  stretchYourDollar: {
    label: 'Greater Value and Savings',
    url: 'https://www.fairprice.com.sg/tips/money-saving/enjoy-greater-value-and-savings-with-fairprice/',
    isAbsoluteUrl: true,
  },
  corporate: {
    label: 'Corporate',
    url: aboutUsUrl,
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  loyaltyProgramme: {
    label: 'Loyalty Programme',
    url: 'https://www.fairprice.com.sg/loyalty-programme/',
    isAbsoluteUrl: true,
  },
  retailFormats: {
    label: 'Retail Format',
    url: 'https://www.fairprice.com.sg/our-retail-formats/',
    isAbsoluteUrl: true,
  },
  termsAndConditions: {
    label: 'Terms & Conditions',
    url: 'https://help.fairprice.com.sg/hc/en-us/articles/360025882372-Terms-Conditions',
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  privacyNotice: {
    label: 'Privacy Notice',
    url: 'https://help.fairprice.com.sg/hc/en-us/articles/4464676591129-Privacy-Notice-with-effect-from-2-April-2022-',
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  privacyPolicy: {
    label: 'Privacy Policy',
    url: 'https://help.fairprice.com.sg/hc/en-us/articles/360025882432-Privacy-Policy',
    isNewTab: true,
    isAbsoluteUrl: true,
  },
  reportVulnerability: {
    label: 'Report Vulnerability',
    url: 'https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/',
    isNewTab: true,
    isAbsoluteUrl: true,
  },

}

export const HEADER_NAVIGATION_B2C = [
  LINKS.digitalClub,
  LINKS.storeWeeklyAds,
  LINKS.recipes,
  LINKS.tipsForYou,
  LINKS.events,
  LINKS.storeLocator,
  LINKS.justWineClub,
  LINKS.sendEGiftVoucher,
  LINKS.shopForBusiness,
]

export const HEADER_NAVIGATION_B2B = [
  LINKS.storeWeeklyAds,
  LINKS.events,
  LINKS.storeLocator,
  LINKS.sendEGiftVoucher,
  LINKS.shopForPersonal,
]

export const FOOTER_NAVIGATION_B2B = {
  firstColumn: [
    LINKS.help,
    LINKS.aboutUsB2B,
    LINKS.tipsForYou,
    LINKS.events,
    LINKS.sendEGiftVoucher,
    LINKS.shopForPersonal,
  ],
  ourStores: [
    LINKS.storeWeeklyAds,
    LINKS.storeLocator,
    LINKS.loyaltyProgramme,
    LINKS.retailFormats,
    LINKS.stretchYourDollar,
  ],
  newTermsAndPolicy: [LINKS.termsAndConditions, LINKS.privacyNotice],
  oldTermsAndPolicy: [LINKS.termsAndConditions, LINKS.privacyPolicy],
}

export const FOOTER_NAVIGATION_B2C = {
  firstColumn: [
    LINKS.help,
    LINKS.aboutUs,
    LINKS.recipes,
    LINKS.tipsForYou,
    LINKS.events,
    LINKS.sendEGiftVoucher,
    LINKS.shopForBusiness,
  ],
  secondColumn: [LINKS.digitalClub, LINKS.justWineClub, LINKS.plus],
  ourStores: [
    LINKS.storeWeeklyAds,
    LINKS.storeLocator,
    LINKS.loyaltyProgramme,
    LINKS.retailFormats,
    LINKS.stretchYourDollar,
  ],
  newTermsAndPolicy: [LINKS.termsAndConditions, LINKS.privacyNotice],
  oldTermsAndPolicy: [LINKS.termsAndConditions, LINKS.privacyPolicy],
  reportVulnerability: [LINKS.reportVulnerability]
}

export const DESKTOP_LINKS = [
  {
    label: 'Categories',
    url: '/categories',
  },
  {
    label: 'Promotions',
    url: '/promotions',
  },
  {
    label: 'Shopping Lists',
    url: '/shoppinglist',
  },
  {
    label: 'More',
    url: '#',
  },
]

export const MOBILE_NAVIGATION = {
  navPrimary: [
    {
      label: 'Categories',
      url: '/categories',
    },
    {
      label: 'Promotions',
      url: '/promotions',
    },
    {
      label: 'Shopping Lists',
      url: '/shoppinglist',
    },
  ],
  navProfile: [
    {
      label: ACCOUNTS_MENU.profile,
      secondaryText: 'Login/Sign up',
      url: '/accounts',
      Icon: SvgAccount,
    },
    {
      label: ACCOUNTS_MENU.digitalClub,
      url: '/membership/registration',
      Icon: DigitalClubIcon,
    },
    {
      label: 'Preferences',
      url: '/accounts?tab=preferences',
      Icon: PreferencesIcon,
    },
    {
      label: 'Activity',
      url: '/orders',
      Icon: OrdersIcon,
    },
    {
      label: LINKS.help.label,
      url: LINKS.help.url,
      isAbsoluteUrl: true,
      Icon: HelpIcon,
    },
  ],
  accountsMenu: [
    { label: ACCOUNTS_MENU.businessProfile, url: '/accounts?tab=business' },
    {
      label: ACCOUNTS_MENU.payment,
      url: '/accounts?tab=managepayment',
    },
    {
      label: ACCOUNTS_MENU.address,
      url: '/accounts?tab=address',
    },
    {
      label: ACCOUNTS_MENU.vouchers,
      url: '/accounts?tab=vouchers',
    },
    {
      label: ACCOUNTS_MENU.luckyDraw,
      url: '/accounts?tab=campaigns',
    },
    {
      label: ACCOUNTS_MENU.plus,
      url: `${FPG_SINGLE_ACCOUNT_PAGE_DOMAIN_NEW}linkpoints`,
    },
  ],
}

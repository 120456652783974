import React from 'react'
import { withRouter } from 'next/router'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import getConfig from 'next/config'
import { Router } from '../../../routes'
import GlobalContext from '../../../components/GlobalContext'
import { store } from '../../CartProvider'
import { EventTrackingConsumer } from '../../EventTrackingProvider'
import dynamic from 'next/dynamic'
import { IdleQueue } from '../../../lib/idlize/IdleQueue'
const queue = new IdleQueue()
const Loader = dynamic(() => import('../../Loader'))
import {
  getNCheckFn,
  GTM_ENV_VAR,
  PAGE_TYPES,
  EVENT_CATEGORIES,
} from '../../../lib/eventTrackerFunction'
import { get } from './../../../lib/fetch'
const SearchSuggestion = dynamic(() => import('./../../SearchSuggestion'))
import LocalStorageService from '../../../utils/LocalStorageService'
import { generateQueryString } from '../../../lib/filterFormatter'
import { disablePageScroll, getCtaLocationMeta } from '../../../lib/utils'
import s from './styles.js'
import { AMP_EVENT_NAME as AEN, getPageTypeByUrl } from '../../../lib/amplitude'
import TrendingSearch from '../../TrendingSearch/TrendingSearch'
import TrendingLoader from '../../TrendingSearch/TrendingLoader'
import SprigService from '../../../utils/SprigService'
import nookies from 'nookies'
import { AUTH_TOKEN_KEY } from '../../AccountProvider/AccountProvider'
import { getConfigFor } from '../../../utils/configService'
import { SPLIT_FEATURES } from '../../../utils/constants'
import { includeAllowedProps } from '../../../lib/segment'
import { getGA4SuggestedImpressions } from '../../../utils/Utils'
import { trackingSearchCompletedForDY } from '../../../utils/DyUtils'
const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

const VIEW_ALL_ENTER = 'View All_enter'
const RECENT_SEARCH_TERM_COUNT = 10 // max number of recent search terms
const DEFAULT_PLACEHOLDER_TEXT = 'Search for products'
export const SearchResult = s.SearchResult
export const SearchResultOnFirstRow = s.SearchResultOnFirstRow
class SearchBar extends React.Component {
  constructor(props) {
    super(props)

    const searchTermFromQuery = props.router?.query?.query || ''
    const supPackageSuggestions = props.supplierPackageSuggestions || []

    this.state = {
      term: searchTermFromQuery,
      recentSearches: [],
      focused: false,
      showResult: false,
      searchSuggestions: [],
      showOverlay: false,
      loading: false,
      searchAbandoned: false,
      searchInputPlaceholderText: '',
      supplierPackageSuggestions: supPackageSuggestions,
      trendingSearchSuggestions: supPackageSuggestions,
      isInputBlurred: false,
      isTrendingError: false,
      isTrendingLoading: false,
    }
    this.ref = React.createRef()
    this.search = debounce(this.search.bind(this), 300)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateRecentSearches = this.updateRecentSearches.bind(this)
    this.generateSearchResultStyle = this.generateSearchResultStyle.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClearSearch = this.handleClearSearch.bind(this)
    this.handleResults = this.handleResults.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.onSuggestionClick = this.onSuggestionClick.bind(this)
    this.getSuggestionsImpressions = this.getSuggestionsImpressions.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.onHandleEvents = this.onHandleEvents.bind(this)
    this.handleViewAllClick = this.handleViewAllClick.bind(this)
    this.updateRecentSearchesFormat = this.updateRecentSearchesFormat.bind(this)
    this.handleResetFocusOnScroll = debounce(
      this.handleResetFocusOnScroll.bind(this),
      10
    )
    this.onhandleRemoveClick = this.onhandleRemoveClick.bind(this)
  }

  handleSearchChange(term) {
    this.setState({ term: term })
  }

  getSuggestionsImpressions(eventAction = '', selectedSuggestion = {}) {
    const uniqueTypes = []
    const groupedByType = {}
    const impressionsObj = {}
    const countByType = {}
    let suggestionPositionWithinType = ''
    const collection =
      selectedSuggestion?.isShowingPastSearches || eventAction === 'SearchStart'
        ? this.state.supplierPackageSuggestions
        : this.state.searchSuggestions
    collection.forEach(suggestion => {
      if (suggestion.type !== 'pastSearch') {
        if (uniqueTypes.includes(suggestion.type)) {
          groupedByType[suggestion.type].push(suggestion.name)
        } else {
          uniqueTypes.push(suggestion.type)
          groupedByType[suggestion.type] = []
          groupedByType[suggestion.type].push(suggestion.name)
        }
      }
    })
    uniqueTypes.forEach(type => {
      countByType[type] = groupedByType[type].length
      return (impressionsObj[`suggested_${type}_impression`] =
        groupedByType[type].join(','))
    })

    //overall suggestion impressions
    if (['SearchComplete', 'SearchAbandon'].includes(eventAction)) {
      const suggestionNames = []
      this.state.searchSuggestions.forEach(suggestion => {
        if (suggestion?.name) {
          if (suggestion.type === 'campaign') {
            const croppedName = suggestion.name.slice(0, 20).trim()
            suggestionNames.push(`${croppedName}...`)
          } else {
            suggestionNames.push(suggestion.name)
          }
        }
      })
      impressionsObj['suggested_search_impression'] = suggestionNames.join(',')
    }

    if (!isEmpty(selectedSuggestion)) {
      suggestionPositionWithinType =
        groupedByType[selectedSuggestion.type]?.indexOf(
          selectedSuggestion.name
        ) + 1
    }

    return {
      suggestionsImpressions: impressionsObj,
      countByType: countByType,
      positionWithinType: suggestionPositionWithinType,
    }
  }

  searchTrackingEvent(
    eventCat,
    eventAction,
    type,
    count = 0,
    term = '',
    selectedSuggestion = {}
  ) {
    if (
      eventAction !== 'SearchStart' &&
      eventAction !== 'SearchSuggestion' &&
      eventAction !== 'SearchNoResults' &&
      (term || selectedSuggestion.name)
    ) {
      const data = selectedSuggestion.name || term
      const dyPayload = trackingSearchCompletedForDY(data)
      this.props.track(dyPayload)
    }
    // Adding an alias name GEV to GTM_ENV_VAR for keeping code clean
    const GEV = GTM_ENV_VAR
    const { track } = this.props
    const getNCheck = getNCheckFn
    const eventLabel = {
      ResultCount: selectedSuggestion?.count?.toString() || count.toString(),
    }

    const { isEnabled, configuration } =
      getConfigFor(SPLIT_FEATURES.FE_SEGMENT_SEARCH_COMPLETED) || {}
    const isSegmentEvent = isEnabled && configuration?.is_segment_on

    let event = {
      [GEV.EVENT_CAT]: eventCat,
      [GEV.EVENT_ACTION]: eventAction,
      [GEV.PAGE_TYPE]: PAGE_TYPES['SEARCH_RESULT'],
    }

    // NEW PROPS FOR SEARCH EVENTS STARTS
    const { supplierPackageSuggestions, recentSearches, searchSuggestions } =
      this.state
    const campainItems = supplierPackageSuggestions.length
      ? supplierPackageSuggestions.filter(
          suggestion => suggestion.type === 'campaign'
        )
      : []
    // To support segment values
    const campainItemForSuggestions = searchSuggestions.length
      ? searchSuggestions.filter(suggestion => suggestion.type === 'campaign')
      : []
    const keywordsItemForSuggestions = searchSuggestions.length
      ? searchSuggestions.filter(suggestion => suggestion.type === 'keyword')
      : []
    const trendingItems = supplierPackageSuggestions.length
      ? supplierPackageSuggestions.filter(
          suggestion => suggestion.type === 'keyword'
        )
      : []
    const checkForSegment =
      isSegmentEvent &&
      (type === 'ViewAllResult' ||
        type === VIEW_ALL_ENTER ||
        (type === 'SuggestionClick' &&
          selectedSuggestion?.type === 'keyword') ||
        (type === 'SuggestionClick' &&
          selectedSuggestion?.type === 'campaign' &&
          term))

    const defaultFeatureCount = {
      'campaign items': checkForSegment
        ? campainItemForSuggestions.length
        : campainItems.length,
      'recent items': recentSearches.length,
      'trending items': trendingItems.length,
      'autocomplete items': checkForSegment
        ? keywordsItemForSuggestions.length
        : searchSuggestions.length,
    }
    const actualFeatureCount = []

    for (const label in defaultFeatureCount) {
      if (defaultFeatureCount[label]) {
        actualFeatureCount.push(`${label}: ${defaultFeatureCount[label]}`)
      }
    }

    const checkCampainItemForSuggestions = campainItemForSuggestions.length
      ? campainItemForSuggestions.map(item => item.name).join()
      : []
    const checkKeywordItemForSuggestions = keywordsItemForSuggestions.length
      ? keywordsItemForSuggestions.map(item => item.name).join()
      : []

    const defaultFeatureContent = {
      'campaign items': checkForSegment
        ? checkCampainItemForSuggestions
        : campainItems.map(item => item.name).join(),
      'recent items': recentSearches.map(item => item.name).join(),
      'trending items': trendingItems.map(item => item.name).join(),
      'autocomplete items': checkForSegment
        ? checkKeywordItemForSuggestions
        : searchSuggestions.map(item => item.name).join(),
    }

    const actualFeatureContent = []

    for (const label in defaultFeatureContent) {
      if (defaultFeatureContent[label]) {
        actualFeatureContent.push(`${label}: ${defaultFeatureContent[label]}`)
      }
    }

    // common Properties
    if (
      type === 'RecentSearch' ||
      type === 'TrendingSearches' ||
      type === 'ViewAllResult' ||
      type === VIEW_ALL_ENTER ||
      selectedSuggestion.type === 'keyword' ||
      selectedSuggestion.type === 'campaign'
    ) {
      event[[GEV.SEARCH_FEATURES_COUNT]] = actualFeatureCount
      event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = actualFeatureContent
      event[[GEV.SUGGESTED_SEARCH_IMPRESSION]] = actualFeatureContent
      event[[GEV.FUL_CENTER]] = this.props.checkoutAddress.storeType
    }
    // NEW PROPS FOR SEARCH EVENTS END

    if (type === 'RecentSearch') {
      const getIndex = recentSearches.length
        ? recentSearches.findIndex(
            item => item.name === selectedSuggestion.name
          )
        : ''
      const { suggestionsImpressions } = this.getSuggestionsImpressions(
        eventAction,
        selectedSuggestion
      )
      const ga4SuggestionsImpressions = getGA4SuggestedImpressions(
        supplierPackageSuggestions,
        recentSearches
      )

      // Check any campaign if recent item is present
      const countValue = defaultFeatureCount['campaign items'] || 0
      // If campaign present then add it into current position
      const actualPosition = countValue
        ? countValue + getIndex + 1
        : getIndex + 1

      event[[GEV.KEYWORD_SELECTED]] = getNCheck(selectedSuggestion, 'name', '')
      event[[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED]] = 'recent'
      event[[GEV.SEARCH_POSITION]] = actualPosition
      event[[GEV.AMPLITUDE_ITEM_POSITIONS]] = actualPosition
      event[[GEV.EVENT_LABEL]] = 'SearchFeatureSelected: recent'

      if (isSegmentEvent) {
        // override common for segment due to datatype change
        event[[GEV.AMPLITUDE_SEARCH_TERM_SELECTED]] = getNCheck(
          selectedSuggestion,
          'name',
          ''
        )
        event[[GEV.SEARCH_FEATURES_COUNT]] = includeAllowedProps(
          defaultFeatureCount,
          'recent',
          false
        )
        event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = includeAllowedProps(
          defaultFeatureContent,
          'recent',
          true
        )
      }
      event = {
        ...suggestionsImpressions,
        ...event,
        ...ga4SuggestionsImpressions,
      }
    } else if (type === 'ViewAllResult' || type === VIEW_ALL_ENTER) {
      const paramToPass = type === 'ViewAllResult' ? 'viewall' : 'viewall_enter'
      const { suggestionsImpressions } =
        this.getSuggestionsImpressions(eventAction)
      const ga4SuggestionsImpressions = getGA4SuggestedImpressions(
        [],
        [],
        searchSuggestions
      )
      event[[GEV.KEYWORD_SELECTED]] =
        getNCheck(selectedSuggestion, 'name', '') || term
      event[[GEV.SEARCH_TERM]] = term
      event[[GEV.SEARCH_POSITION]] = -1
      event[[GEV.AMPLITUDE_ITEM_POSITIONS]] = -1
      event[[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED]] = paramToPass
      event[[GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]] = term
      event[[GEV.EVENT_LABEL]] = `SearchFeatureSelected: ${paramToPass}`

      if (isSegmentEvent) {
        // override common for segment due to datatype change
        event[[GEV.SEARCH_FEATURES_COUNT]] = includeAllowedProps(
          defaultFeatureCount,
          paramToPass,
          false
        )
        event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = includeAllowedProps(
          defaultFeatureContent,
          paramToPass,
          true
        )
        event[[GEV.AMPLITUDE_SEARCH_TERM_SELECTED]] = term
      }

      event = {
        ...suggestionsImpressions,
        ...event,
        ...ga4SuggestionsImpressions,
      }
    } else if (type === 'SuggestionClick') {
      let ga4Params = {}
      const position = getNCheck(selectedSuggestion, 'position', '')
      const { suggestionsImpressions, countByType, positionWithinType } =
        this.getSuggestionsImpressions(eventAction, selectedSuggestion)
      event[[GEV.SEARCH_TERM]] = term
      event[[GEV.EVENT_LABEL]] = JSON.stringify({
        ...eventLabel,
        SearchType: `Suggested${capitalize(selectedSuggestion.type)}s`,
        ResultCount: countByType[selectedSuggestion.type]?.toString() || '',
        SearchPos: positionWithinType,
      })
      event[[GEV.KEYWORD_SELECTED]] = getNCheck(selectedSuggestion, 'name', '')
      event[[GEV.SEARCH_POSITION]] = position ? Number(position) : -1

      if (selectedSuggestion.type === 'keyword') {
        const getSelectedItemIndex = searchSuggestions.length
          ? searchSuggestions.findIndex(
              item => item.name === selectedSuggestion.name
            )
          : ''
        event[[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED]] = 'autocomplete'
        event[[GEV.AMPLITUDE_ITEM_POSITIONS]] = getSelectedItemIndex + 1
        event[[GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]] = term
        event[[GEV.EVENT_LABEL]] = 'SearchFeatureSelected: autocomplete'

        const ga4SuggestionsImpressions = getGA4SuggestedImpressions(
          [],
          [],
          searchSuggestions
        )

        ga4Params = ga4SuggestionsImpressions

        if (isSegmentEvent) {
          // override common for segment due to datatype change
          event[[GEV.AMPLITUDE_SEARCH_TERM_SELECTED]] = getNCheck(
            selectedSuggestion,
            'name',
            ''
          )
          event[[GEV.SEARCH_FEATURES_COUNT]] = includeAllowedProps(
            defaultFeatureCount,
            'autocomplete',
            false
          )
          event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = includeAllowedProps(
            defaultFeatureContent,
            'autocomplete',
            true
          )
        }
      }

      if (selectedSuggestion.type === 'campaign') {
        event[[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED]] = 'campaign'
        event[[GEV.AMPLITUDE_ITEM_POSITIONS]] = 1
        event[[GEV.EVENT_LABEL]] = 'SearchFeatureSelected: campaign'

        let newData
        if (term) {
          newData = getGA4SuggestedImpressions([], [], searchSuggestions)
        } else {
          newData = getGA4SuggestedImpressions(
            supplierPackageSuggestions,
            recentSearches,
            []
          )
        }

        ga4Params = newData

        if (isSegmentEvent) {
          // override common for segment due to datatype change
          event[[GEV.AMPLITUDE_SEARCH_TERM_SELECTED]] = getNCheck(
            selectedSuggestion,
            'name',
            ''
          )

          if (term) {
            event[[GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]] = term
            event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = includeAllowedProps(
              defaultFeatureContent,
              'autocomplete',
              true
            )
            event[[GEV.SEARCH_FEATURES_COUNT]] = includeAllowedProps(
              defaultFeatureCount,
              'autocomplete',
              false
            )
          } else {
            event[[GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]] = null
            event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = includeAllowedProps(
              defaultFeatureContent,
              'campaign',
              true
            )
            event[[GEV.SEARCH_FEATURES_COUNT]] = includeAllowedProps(
              defaultFeatureCount,
              'campaign',
              false
            )
          }
        }
      }

      event = {
        ...suggestionsImpressions,
        ...event,
        ...ga4Params,
      }
    } else if (type === 'NoResult') {
      event[[GEV.SEARCH_TERM]] = term
    } else if (type === 'SearchAbandon') {
      const { suggestionsImpressions } =
        this.getSuggestionsImpressions(eventAction)
      event[[GEV.SEARCH_RES_COUNT]] = count.toString()
      event[[GEV.EVENT_LABEL]] = ''
      event = {
        ...event,
        ...suggestionsImpressions,
      }
    } else if (type === 'Start') {
      const eventType = this.state.term.length ? '' : 'SearchStart'
      const { suggestionsImpressions } =
        this.getSuggestionsImpressions(eventType)
      let ga4SuggestionsImpressions
      if (this.state.term) {
        ga4SuggestionsImpressions = getGA4SuggestedImpressions(
          [],
          [],
          searchSuggestions
        )
      } else {
        ga4SuggestionsImpressions = getGA4SuggestedImpressions(
          supplierPackageSuggestions,
          recentSearches,
          []
        )
      }
      event[[GEV.KEYWORD_SELECTED]] = this.state.term
      event[[GEV.SNIPPET_VERSION]] = 'ga4'
      event[[GEV.PAGE_TYPE]] = getPageTypeByUrl()
      event = {
        ...event,
        ...suggestionsImpressions,
        ...ga4SuggestionsImpressions,
      }
    } else if (type === 'SearchViewed') {
      event[[GEV.SNIPPET_VERSION]] = 'ga4'
      event[[GEV.PAGE_TYPE]] = getPageTypeByUrl()
      event[
        [GEV.EVENT_LABEL]
      ] = `Label=${this.state.searchInputPlaceholderText}`
    }

    // Properties for TrendingSearch
    if (type === 'TrendingSearches') {
      const ga4SuggestionsImpressions = getGA4SuggestedImpressions(
        supplierPackageSuggestions,
        recentSearches
      )

      const getSelectedItemIndex = supplierPackageSuggestions.length
        ? supplierPackageSuggestions
            .filter(suggestion => suggestion.type === 'keyword')
            .findIndex(item => item.name === selectedSuggestion.name)
        : ''
      event[[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED]] = 'trending'
      event[[GEV.FUL_CENTER]] = this.props.checkoutAddress.storeType
      event[[GEV.SUGGESTED_CAMPAIGN_IMPRESSION]] = campainItems
        .map(item => item.name)
        .join()
      event[[GEV.KEYWORD_SELECTED]] = getNCheck(selectedSuggestion, 'name', '')
      event[[GEV.SEARCH_POSITION]] = getSelectedItemIndex + 1
      event[[GEV.AMPLITUDE_ITEM_POSITIONS]] = getSelectedItemIndex + 1
      event[[GEV.EVENT_LABEL]] = 'SearchFeatureSelected: trending'

      if (isSegmentEvent) {
        // override common for segment due to datatype change
        event[[GEV.AMPLITUDE_SEARCH_TERM_SELECTED]] = getNCheck(
          selectedSuggestion,
          'name',
          ''
        )
        event[[GEV.SEARCH_FEATURES_COUNT]] = includeAllowedProps(
          defaultFeatureCount,
          'trending',
          false
        )
        event[[GEV.SUGGESTED_KEYWORD_IMPRESSION]] = includeAllowedProps(
          defaultFeatureContent,
          'trending',
          true
        )
      }

      event = {
        ...event,
        ...ga4SuggestionsImpressions,
      }
    }

    // New GA4 Event for Search
    if (type === 'SearchSuggestionResult') {
      const ga4SuggestionsImpressions = getGA4SuggestedImpressions(
        [],
        [],
        searchSuggestions
      )
      event[[GEV.KEYWORD_SELECTED]] = term
      event[[GEV.FUL_CENTER]] = this.props.checkoutAddress.storeType
      event[[GEV.SNIPPET_VERSION]] = 'ga4'
      event[[GEV.PAGE_TYPE]] = getPageTypeByUrl()

      event = {
        ...event,
        ...ga4SuggestionsImpressions,
      }
    }

    if (eventAction === 'SearchComplete') {
      event[[GEV.AMPLITUDE_EVENT_NAME]] = AEN.SEARCH_COMPLETED
      event[[GEV.AMPLITUDE_CTA_LOCATION]] = {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      }
      event[[GEV.SNIPPET_VERSION]] = 'ga4'
      event[[GEV.PAGE_TYPE]] = getPageTypeByUrl()
      event[[GEV.IS_SEGEMENT_EVENT]] = isSegmentEvent
    }
    track(event)
  }

  onHandleEvents(count, term, type, suggestionData = {}) {
    if (type === 'Recent') {
      this.setState({
        term: term,
        recentSearchSelected: true,
        searchAbandoned: false,
      })
      this.searchTrackingEvent(
        EVENT_CATEGORIES.PRODUCT_CORE,
        'SearchComplete',
        'RecentSearch',
        this.state.recentSearches.length,
        term,
        suggestionData
      )
    } else if (type === 'ViewAllResult') {
      this.setState({ searchAbandoned: false })
      this.searchTrackingEvent(
        EVENT_CATEGORIES.PRODUCT_CORE,
        'SearchComplete',
        'ViewAllResult',
        count,
        term
      )
    } else if (type === VIEW_ALL_ENTER) {
      this.setState({ searchAbandoned: false })
      this.searchTrackingEvent(
        EVENT_CATEGORIES.PRODUCT_CORE,
        'SearchComplete',
        VIEW_ALL_ENTER,
        count,
        term
      )
    }
  }

  onSuggestionClick(selectedSuggestion = {}, isTrendingSearch = false) {
    const { term: prevTerm } = this.state
    const recentSearchToUpdate =
      selectedSuggestion?.type === 'keyword' ? selectedSuggestion?.name : ''
    const totalSuggestionCount = this.state.term
      ? this.state.searchSuggestions.length
      : this.state.supplierPackageSuggestions.length
    const searchType = !isTrendingSearch
      ? 'SuggestionClick'
      : 'TrendingSearches'
    this.setState(
      {
        term: recentSearchToUpdate,
        showOverlay: false,
        searchAbandoned: false,
      },
      (() => {
        this.searchTrackingEvent(
          EVENT_CATEGORIES.PRODUCT_CORE,
          'SearchComplete',
          searchType,
          totalSuggestionCount,
          prevTerm,
          selectedSuggestion
        )
      }).bind(this)
    )
  }

  search({ term = '', getSupplierPackage = false }) {
    if (term && term.length) {
      this.setState({ loading: true, isInputBlurred: false }, async () => {
        try {
          const apiUrl = `${API_URL}/suggestions`
          const suggestionQueryParams = {
            text: encodeURIComponent(term),
            storeId: this.props.checkoutAddress?.storeId || '',
            experiments: this.props.getTestVersions(),
          }
          const suggestionParams = generateQueryString(suggestionQueryParams)
          const { [AUTH_TOKEN_KEY]: authToken } = nookies.get() || {}
          const suggestionResp = await get(
            apiUrl + suggestionParams,
            authToken
              ? {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                  },
                }
              : {}
          )
          const searchSuggestionsObj = suggestionResp?.data?.suggestions || []
          if (!searchSuggestionsObj.length) {
            this.searchTrackingEvent(
              EVENT_CATEGORIES.PRODUCT_CORE,
              'SearchNoResults',
              'NoResult',
              0,
              term
            )
          }
          SprigService.track('grocery_online_search_completed')
          this.setState(
            {
              searchSuggestions: searchSuggestionsObj,
              loading: false,
              showResult: true,
              error: null,
            },
            () => {
              // New GA4 Event for Search
              if (searchSuggestionsObj.length) {
                this.searchTrackingEvent(
                  EVENT_CATEGORIES.PRODUCT_CORE,
                  'SearchSuggestion',
                  'SearchSuggestionResult',
                  0,
                  term
                )
              }
            }
          )
        } catch (error) {
          this.setState({
            error: error.message,
            loading: false,
          })
        }
      })
    } else if (!term.length && getSupplierPackage) {
      this.setState({ loading: true, isTrendingLoading: true }, async () => {
        try {
          const apiUrl = `${API_URL}/suggestions`
          const suggestionQueryParams = {
            storeId: this.props.checkoutAddress?.storeId || '',
            experiments: this.props.getTestVersions(),
          }
          const suggestionParams = generateQueryString(suggestionQueryParams)
          const { [AUTH_TOKEN_KEY]: authToken } = nookies.get() || {}
          const suggestionResp = await get(
            apiUrl + suggestionParams,
            authToken
              ? {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                  },
                }
              : {}
          )
          const searchSuggestionsObj = suggestionResp?.data?.suggestions || []
          const searchInputPlaceholderText =
            suggestionResp?.data?.defaultText || ''
          this.setState(
            {
              supplierPackageSuggestions: searchSuggestionsObj,
              searchInputPlaceholderText: searchInputPlaceholderText,
              trendingSearchSuggestions: searchSuggestionsObj.filter(
                item => item.type === 'keyword'
              ),
              loading: false,
              showResult: true,
              error: null,
              isTrendingLoading: false,
            },
            () => {
              if (searchInputPlaceholderText !== '') {
                this.searchTrackingEvent(
                  EVENT_CATEGORIES.PRODUCT_CORE,
                  'SearchViewed',
                  'SearchViewed'
                )
              }
              if (searchSuggestionsObj.length && this.state.focused) {
                this.searchTrackingEvent(
                  EVENT_CATEGORIES.PRODUCT_CORE,
                  'SearchStart',
                  'Start'
                )
              }
            }
          )
        } catch (error) {
          this.setState({
            error: error.message,
            loading: false,
            isTrendingLoading: false,
            isTrendingError: true,
          })
        }
      })
    }
  }

  handleChange(value) {
    if (value.startsWith(' ')) {
      value = value.trim()
    }
    this.handleSearchChange(value)

    let searchSuggestions = this.state.searchSuggestions
    if (value === '') {
      // New GA4 Event for Search
      this.searchTrackingEvent(
        EVENT_CATEGORIES.PRODUCT_CORE,
        'SearchSuggestion',
        'SearchSuggestionResult',
        0,
        this.state.term
      )
      searchSuggestions = []
    }
    this.setState(
      prevState => {
        const newState = Object.assign({}, prevState)
        newState.showResult = true
        newState.term = value
        newState.searchSuggestions = searchSuggestions

        return newState
      },
      () => {
        if (value === '') {
          this.search({ term: '', getSupplierPackage: true })
        } else {
          this.search({ term: value })
        }
        this.props.onHandleBackdrop && this.props.onHandleBackdrop(true, this)
      }
    )
  }

  handleClearSearch() {
    if (!isEmpty(this.state.searchSuggestions)) {
      this.searchTrackingEvent(
        EVENT_CATEGORIES.PRODUCT_CORE,
        'SearchAbandon',
        'SearchAbandon'
      )
      // New GA4 Event for Search
      this.searchTrackingEvent(
        EVENT_CATEGORIES.PRODUCT_CORE,
        'SearchSuggestion',
        'SearchSuggestionResult',
        0,
        this.state.term
      )
    }
    this.handleSearchChange('')
    this.search({ getSupplierPackage: true })
    this.setState(
      prevState => {
        return {
          ...prevState,
          term: '',
          focused: false,
          showResult: false,
          searchSuggestions: [],
        }
      },
      (() => {
        this.searchInputRef && this.searchInputRef.blur()
      }).bind(this)
    )
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.state.term) {
      const { isEnabled, configuration } =
        getConfigFor(SPLIT_FEATURES.FE_SEGMENT_SEARCH_COMPLETED) || {}
      const isSegmentEvent = isEnabled && configuration?.is_segment_on

      const { searchSuggestions: prevSearchSuggestions } = this.state
      let type = isSegmentEvent ? VIEW_ALL_ENTER : 'ViewAllResult'
      // to support GA4 check event type
      if (event?.type === 'submit') {
        type = VIEW_ALL_ENTER
      }
      this.setState({ showResult: false, showOverlay: false }, () => {
        this.onHandleEvents(prevSearchSuggestions.length, this.state.term, type)
        this.searchInputRef && this.searchInputRef.blur()
        Router.pushRoute(`/search?query=${encodeURIComponent(this.state.term)}`)
      })
    }
  }

  updateRecentSearches(term) {
    if (!term?.length) {
      return
    }
    const { recentSearches } = this.state
    let newRecentSearches = !isEmpty(recentSearches)
      ? JSON.parse(JSON.stringify(recentSearches))
      : [{ type: 'pastSearch', name: term, slug: term, pageType: 'search' }]
    const isTermInRecentSeaches =
      newRecentSearches.filter(
        newRecentSearch => newRecentSearch.name === term
      ) || []
    if (!isTermInRecentSeaches.length) {
      newRecentSearches.unshift({
        type: 'pastSearch',
        name: term,
        slug: term,
        pageType: 'search',
      })
    }
    newRecentSearches = newRecentSearches.slice(0, RECENT_SEARCH_TERM_COUNT)
    if (!isEqual(newRecentSearches, recentSearches)) {
      this.setState({ recentSearches: newRecentSearches })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      ((prevState.term !== this.state.term &&
        this.state.searchInputPlaceholderText === '') ||
        prevProps.checkoutAddress.storeId !==
          this.props.checkoutAddress.storeId) &&
      this.state.term === ''
    ) {
      queue.pushTask(() => {
        this.search({ getSupplierPackage: true })
      })
    }

    if (
      prevProps.checkoutAddress.storeId !== this.props.checkoutAddress.storeId
    ) {
      this.setState({ searchSuggestions: [] })
    }

    if (!isEqual(this.state.recentSearches, prevState.recentSearches)) {
      LocalStorageService.setItem(
        'search',
        JSON.stringify(this.state.recentSearches)
      )
    }

    /* istanbul ignore next */
    if (prevState.focused !== this.state.focused) {
      this.props.onHandleBackdrop &&
        this.props.onHandleBackdrop(this.state.focused, this)

      if (
        prevState.showOverlay !== this.state.showOverlay &&
        !this.state.showOverlay
      ) {
        disablePageScroll(false)
      }
    } else if (
      this.props.router?.query?.query !== prevProps.router?.query?.query
    ) {
      this.updateRecentSearches(this.props.router?.query?.query)
      this.setState(
        {
          showResult: false,
          focused: false,
          term: this.props.router?.query?.query || '',
        },
        () => {
          this.props.onHandleBackdrop &&
            this.props.onHandleBackdrop(false, this)
          disablePageScroll(this.state.showOverlay)
        }
      )
    }

    if (this.state.recentSearchSelected) {
      this.setState({
        recentSearchSelected: false,
      })
    }
  }

  handleResults(e) {
    e && e.stopPropagation()
    if (e && !e.target.dataset?.testid?.toString().includes('search-input-')) {
      if (
        !isEmpty(this.state.searchSuggestions) &&
        this.state.searchAbandoned
      ) {
        this.searchTrackingEvent(
          EVENT_CATEGORIES.PRODUCT_CORE,
          'SearchAbandon',
          'SearchAbandon'
        )
      }
      this.setState({
        showResult: false,
        focused: false,
        searchAbandoned: false,
      })
    }
  }

  handleFocus() {
    this.setState(
      { focused: true, showResult: true, showOverlay: true },
      () => {
        const { term } = this.state
        if (term) {
          this.search({ term: term })
        }
      }
    )
  }

  handleBlur() {
    if (!this.state.searchSuggestions?.length) {
      this.setState({ showOverlay: false, searchAbandoned: true })
    } else {
      this.setState({ searchAbandoned: true })
    }
  }

  handleResetFocusOnScroll = () => {
    if (!this.state.isInputBlurred) {
      this.searchInputRef.blur()
      this.setState({ isInputBlurred: true })
    }
  }

  updateRecentSearchesFormat(recentSearches = []) {
    let formattedRecentSearches = []
    if (recentSearches.length && !recentSearches[0]?.hasOwnProperty('type')) {
      recentSearches.forEach(recentSearch => {
        formattedRecentSearches.push({
          type: 'pastSearch',
          name: recentSearch,
          slug: recentSearch,
          pageType: 'search',
        })
      })
    } else {
      formattedRecentSearches = recentSearches
    }
    return formattedRecentSearches
  }

  componentDidMount() {
    let recentSearches = store('search')()
    recentSearches = this.updateRecentSearchesFormat(recentSearches)
    const term = this.props.router?.query?.query || ''
    if (!term.length) {
      this.search({ getSupplierPackage: true })
    }

    if (!Array.isArray(recentSearches)) {
      recentSearches = []
    }
    const isTermInRecentSeaches =
      recentSearches.filter(recentSearch => recentSearch.name === term) || []
    if (term && !isTermInRecentSeaches.length) {
      recentSearches = [
        { type: 'pastSearch', name: term, slug: term, pageType: 'search' },
        ...recentSearches,
      ].slice(0, RECENT_SEARCH_TERM_COUNT)
    } else {
      recentSearches = recentSearches.slice(0, RECENT_SEARCH_TERM_COUNT)
    }

    this.setState({
      recentSearches: recentSearches,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
    window.addEventListener('click', this.handleResults, false)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleResults, false)
  }

  handleViewAllClick() {
    this.onHandleEvents(
      this.state.searchSuggestions.length,
      this.state.term,
      'ViewAllResult'
    )
    this.setState({ showResult: false, searchAbandoned: false })
  }

  onhandleRemoveClick(name) {
    // Handle remove from past search items
    if (!name) {
      return
    }
    const pastSearchItems = this.state.recentSearches?.filter(
      item => item.name !== name
    )
    this.setState({ recentSearches: pastSearchItems })
    LocalStorageService.setItem('search', JSON.stringify(pastSearchItems))
  }

  generateSearchResultStyle(SearchResultControl = SearchResult) {
    const {
      searchSuggestions,
      loading,
      focused,
      recentSearches,
      term,
      showResult,
      supplierPackageSuggestions,
      isTrendingLoading,
      isTrendingError,
      trendingSearchSuggestions,
    } = this.state
    let pastSearches = (!term && recentSearches.length && recentSearches) || []
    pastSearches = pastSearches.filter(Boolean)
    pastSearches = supplierPackageSuggestions.concat(pastSearches)

    if (!term) {
      pastSearches = pastSearches.filter(item => item.type !== 'keyword')
    }

    const showPastSearches = focused && pastSearches.length ? 1 : 0

    return (
      (searchSuggestions.length || loading || focused || showResult) && (
        <s.StyledSuggestionWrapper onScroll={this.handleResetFocusOnScroll}>
          <SearchResultControl
            windowWidth={this.state.windowWidth}
            windowHeight={this.state.windowHeight}
            isShown={focused}
            hideBorder={!pastSearches.length}
          >
            {!term && showPastSearches ? (
              <SearchSuggestion
                searchSuggestions={pastSearches}
                searchTerm={term}
                handleSuggestionClick={this.onSuggestionClick}
                handlePastSearchClick={this.onHandleEvents}
                loading={loading}
                isShowingPastSearches={true}
                dataTestId="past-searches-container"
                handleRemoveClick={this.onhandleRemoveClick}
              />
            ) : (
              ''
            )}
            {!term && isTrendingLoading ? <TrendingLoader /> : ''}
            {!term &&
            !isTrendingError &&
            !isTrendingLoading &&
            trendingSearchSuggestions.length ? (
              <TrendingSearch
                handleSuggestionClick={this.onSuggestionClick}
                isSuggestion={showPastSearches ? true : false}
                isTrendingloading={isTrendingLoading}
                data={trendingSearchSuggestions.slice(0, 10)}
                data-testid="trend"
              />
            ) : (
              ''
            )}
            {term &&
              (loading ? (
                <Loader />
              ) : (
                <SearchSuggestion
                  searchSuggestions={searchSuggestions}
                  searchTerm={term}
                  handleSuggestionClick={this.onSuggestionClick}
                  loading={loading}
                  onHandleViewAllClick={this.handleViewAllClick}
                  isShowingPastSearches={false}
                  dataTestId="suggestions-container"
                />
              ))}
          </SearchResultControl>
        </s.StyledSuggestionWrapper>
      )
    )
  }

  render() {
    const { searchResultStyle, dataTestId, deliveryModeB2B } = this.props
    const { focused, showOverlay, term } = this.state
    return (
      <React.Fragment>
        {focused && showOverlay && disablePageScroll(true)}
        <s.StyledInputWrapper ref={this.ref}>
          <s.StyledWrapper className={focused ? 'formFocused' : ''}>
            <form
              data-testid="search-form"
              action="/search"
              method="GET"
              onSubmit={this.handleSubmit}
              onFocus={() =>
                this.searchTrackingEvent(
                  EVENT_CATEGORIES.PRODUCT_CORE,
                  'SearchStart',
                  'Start'
                )
              }
            >
              <s.StyledInput
                deliveryModeB2B={deliveryModeB2B}
                id="search-input-bar"
                ref={node => (this.searchInputRef = node)}
                data-testid={`search-input-${dataTestId}`}
                placeholder={
                  this.state.searchInputPlaceholderText ||
                  DEFAULT_PLACEHOLDER_TEXT
                }
                type="text"
                name="query"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={event => this.handleChange(event.target.value)}
                value={term}
                maxLength={100}
                autoComplete="off"
              />
              <s.StyledSearchIcon
                deliveryModeB2B={deliveryModeB2B}
                title="Search"
              />
              {term && (
                <s.StyledClearIcon
                  deliveryModeB2B={deliveryModeB2B}
                  title="clearIcon"
                  onClick={this.handleClearSearch}
                />
              )}
              <s.StyledPopupWrapper />
              <s.StyledSubmit type="submit">Search</s.StyledSubmit>
            </form>
            {this.state.showResult &&
              this.generateSearchResultStyle(searchResultStyle)}
          </s.StyledWrapper>
        </s.StyledInputWrapper>
      </React.Fragment>
    )
  }
}

SearchBar.defaultProps = {
  onChange: () => {},
  renderResults: () => null,
  term: '',
}

export default withRouter(props => (
  <GlobalContext.Consumer>
    {({ experiments }) => (
      <EventTrackingConsumer>
        {({ track, getTestVersions }) => (
          <SearchBar
            supplierPackageSuggestions={[]}
            {...props}
            track={track}
            experiments={experiments}
            getTestVersions={getTestVersions}
          />
        )}
      </EventTrackingConsumer>
    )}
  </GlobalContext.Consumer>
))
